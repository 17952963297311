import React from 'react';
import { FaIdCard, FaBuilding, FaUniversity, FaBalanceScale, FaUserShield } from 'react-icons/fa';
import './Home.css';

function Home() {
  return (
    <div className="container mt-4">
      <h1 className="title">BIENVENIDO AL SISTEMA DE INTEROPERABILIDAD</h1>
      <p className="description">
      El propósito de esta plataforma de interoperabilidad del GRML es ofrecer a los usuarios una herramienta centralizada y eficiente para realizar consultas a diversos servicios de exposición de datos provenientes de otras instituciones del Estado Peruano, facilitando así el acceso y la gestión de la información de manera ágil y segura.
      </p>
      <div className="institutions">
        <div className="institution">
          <a href="/query-dni" className="institution-link">
            <FaIdCard className="institution-icon" />
            <span className="institution-name">&nbsp;Reniec </span>
          </a>
        </div>
        <div className="institution">
          <a href="/query-ruc" className="institution-link">
            <FaBuilding className="institution-icon" />
            <span className="institution-name">&nbsp;Sunat  </span>
          </a>
        </div>
        <div className="institution">
          <a href="/minedu-grados-titulos" className="institution-link">
            <FaUniversity className="institution-icon" />
            <span className="institution-name">&nbsp;Minedu </span>
          </a>
        </div>
        <div className="institution">
          <a href="/minjus-abogados-sancionados" className="institution-link">
            <FaBalanceScale className="institution-icon" />
            <span className="institution-name">&nbsp;Minjus </span>
          </a>
        </div>
        <div className="institution">
          <a href="/mininter-antecedentes-penales" className="institution-link">
            <FaUserShield className="institution-icon" />
            <span className="institution-name">&nbsp;Mininter </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
