import React, { useState, useEffect } from "react";
import axios from "axios";

function QueryByComisarias() {
  const [departamento, setDepartamento] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");
  const [comisaria, setComisaria] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [comisarias, setComisarias] = useState([]);

  useEffect(() => {
    // Simulación de API para cargar los departamentos al montar el componente
    axios.get("/api/departamentos").then((response) => {
      setDepartamentos(response.data);
    });
  }, []);

  useEffect(() => {
    if (departamento) {
      // Actualizar provincias según el departamento seleccionado
      axios.get(`/api/provincias/${departamento}`).then((response) => {
        setProvincias(response.data);
        setDistrito(""); // Limpiar selección de distrito
        setComisaria(""); // Limpiar selección de comisaría
        setDistritos([]);
        setComisarias([]);
      });
    }
  }, [departamento]);

  useEffect(() => {
    if (provincia) {
      // Actualizar distritos según la provincia seleccionada
      axios.get(`/api/distritos/${provincia}`).then((response) => {
        setDistritos(response.data);
        setComisaria(""); // Limpiar selección de comisaría
        setComisarias([]);
      });
    }
  }, [provincia]);

  useEffect(() => {
    if (distrito) {
      // Actualizar comisarías según el distrito seleccionado
      axios.get(`/api/comisarias/${distrito}`).then((response) => {
        setComisarias(response.data);
      });
    }
  }, [distrito]);

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h3>MININTER</h3>
          <p>
            <i>Consulta de Antecedentes Penales</i>
          </p>
          <hr className="hr_grados" />
          <div className="mb-3">
            <label htmlFor="departamentoSelect" className="form-label">
              <strong>Departamento:</strong>
            </label>
            <select
              id="departamentoSelect"
              className="form-select"
              value={departamento}
              onChange={(e) => setDepartamento(e.target.value)}
            >
              <option value="">Seleccione</option>
              {departamentos.map((dpto) => (
                <option key={dpto.id} value={dpto.id}>
                  {dpto.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="provinciaSelect" className="form-label">
              <strong>Provincia:</strong>
            </label>
            <select
              id="provinciaSelect"
              className="form-select"
              value={provincia}
              onChange={(e) => setProvincia(e.target.value)}
              disabled={!departamento}
            >
              <option value="">Seleccione</option>
              {provincias.map((prov) => (
                <option key={prov.id} value={prov.id}>
                  {prov.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="distritoSelect" className="form-label">
              <strong>Distrito:</strong>
            </label>
            <select
              id="distritoSelect"
              className="form-select"
              value={distrito}
              onChange={(e) => setDistrito(e.target.value)}
              disabled={!provincia}
            >
              <option value="">Seleccione</option>
              {distritos.map((dist) => (
                <option key={dist.id} value={dist.id}>
                  {dist.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="comisariaSelect" className="form-label">
              <strong>Comisaría:</strong>
            </label>
            <select
              id="comisariaSelect"
              className="form-select"
              value={comisaria}
              onChange={(e) => setComisaria(e.target.value)}
              disabled={!distrito}
            >
              <option value="">Seleccione</option>
              {comisarias.map((com) => (
                <option key={com.id} value={com.id}>
                  {com.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={() => alert("Consulta realizada")}
              disabled={!comisaria}
            >
              Consultar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QueryByComisarias;
