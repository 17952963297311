import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Topbar.css';

function Topbar() {

  const userName = Cookies.get('userName') || 'Usuario no identificado';
  const navigate = useNavigate();
  
  const handleLogout = () => {
    // Eliminar cookies
    Cookies.remove('authToken');
    Cookies.remove('userName');
    Cookies.remove('userEmail');
    // Redirigir al inicio de sesión
    navigate('/login');
  };

  return (
    <div className="topbar">
        <div className="system-name">SISTEMA DE INTEROPERABILIDAD - GOBIERNO REGIONAL METROPOLITANA DE LIMA</div>
        <div className="user-info">
            <img src="https://via.placeholder.com/30" alt="User" /> 
            <a href="#" onClick={handleLogout} className="logout-link">
              Usuario: {userName} 
            </a> 
        </div> 
    </div> 
  );
} 

export default Topbar;
