import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { FaSearch } from "react-icons/fa"; // Librería para iconos (react-icons)

function QueryByRUC() {
  const [ruc, setRuc] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga

  const handleQuery = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_SUNAT;
      const response = await axios.get(`${apiUrl}/${ruc}`);
      setResult(response.data);
    } catch (error) {
      alert('Error al consultar RUC');
    }
  };

  const exportToExcel = () => {
    if (!result) {
      alert('No hay datos para exportar');
      return;
    }

    // Transformar los datos en un formato para Excel
    const data = [ 
      { TITULO: 'Razón Social', DESCRIPCION: result.data.razonSocial },
      { TITULO: 'Nombre Comercial', DESCRIPCION: result.data.nombreComercial },
      { TITULO: 'RUC', DESCRIPCION: result.data.ruc },
      { TITULO: 'Estado', DESCRIPCION: result.data.estado },
      { TITULO: 'Condición', DESCRIPCION: result.data.condicion },
      { TITULO: 'Dirección', DESCRIPCION: result.data.direccion }, 
      { TITULO: 'DEPARTAMENTO', DESCRIPCION: result.data.departamento },
      { TITULO: 'PROVINCIA', DESCRIPCION: result.data.provincia },
      { TITULO: 'DISTRITO', DESCRIPCION: result.data.distrito },
      { TITULO: 'UBIGEO', DESCRIPCION: result.data.ubigeo }, 
      { TITULO: 'Fecha de Inscripción', DESCRIPCION: result.data.fechaInscripcion },
    ];

    // Crear un libro y una hoja para Excel
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Añadir la hoja al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Consulta RUC');

    // Exportar el archivo
    XLSX.writeFile(workbook, `Consulta_RUC_${ruc}.xlsx`);
  };

  return (
    <div className="container mt-5">
    <div className="card shadow-lg"> 
    <div className="card-body">
    <h3>SUNAT</h3>
    <o><i>Consulta por RUC</i></o>
    <hr className='hr_grados'></hr>
      <div className="mb-4">
          <label htmlFor="dniInput" className="form-label">
            <strong>Ingrese numero de RUC:</strong>
          </label>
        <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="rucInput"
          value={ruc}
          onChange={(e) => setRuc(e.target.value)}
          maxLength={11}
          placeholder="Ingrese un RUC válido"
        /> 
      <button
        className="btn btn-primary"
        onClick={handleQuery}
        disabled={!ruc || loading}
      >
        {loading ? (
          <span className="spinner-border spinner-border-sm" />
        ) : (
          <FaSearch />
        )}
        <span className="ms-2">Consultar</span>
      </button> 
      {result && (
        <button className="btn btn-success" onClick={exportToExcel}>
          Exportar a Excel
        </button>
      )}
      </div>
      </div>  
      {result && (
        <div className="mt-4">
          <h5 className="mb-3">Resultado de la consulta:</h5>
          <table className="table table-striped table-hover">
            <thead className="table-success">
              <tr>
                <th>TITULO</th>
                <th>DESCRIPCIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Razón Social</td>
                <td>{result.data.razonSocial}</td>
              </tr>
              <tr>
                <td>Nombre Comercial</td>
                <td>{result.data.nombreComercial}</td>
              </tr>
              <tr>
                <td>Ruc</td>
                <td>{result.data.ruc}</td>
              </tr>
              <tr>
                <td>Estado</td>
                <td>{result.data.estado}</td>
              </tr>
              <tr>
                <td>Condición</td>
                <td>{result.data.condicion}</td>
              </tr>
              <tr>
                <td>Dirección</td>
                <td>{result.data.direccion}</td>
              </tr>
              <tr>
                <td>Departamento</td>
                <td>{result.data.departamento}</td>
              </tr>
              <tr>
                <td>Provincia</td>
                <td>{result.data.provincia}</td>
              </tr>
              <tr>
                <td>Distrito</td>
                <td>{result.data.distrito}</td>
              </tr>
              <tr>
                <td>Ubigeo</td>
                <td>{result.data.ubigeo}</td>
              </tr> 
              <tr>
                <td>Fecha de Inscripción</td>
                <td>{result.data.fechaInscripcion}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
    </div>
    </div>
  );
}

export default QueryByRUC;


