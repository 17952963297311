import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Sidebar from './components/Sidebar';  
import Topbar from './components/Topbar'; 
import Login from './components/Login';
import Home from './pages/Home';
import QueryByDNI from './pages/QueryByDNI';
import QueryByRUC from './pages/QueryByRUC';
import QueryBySUNEDU from './pages/QueryBySUNEDU';
import QueryByMININTER from './pages/QueryByMININTER';
import QueryByMINJUS from './pages/QueryByMINJUS';
import QueryByMINEDU from './pages/QueryByMINEDU';  
import QueryByGradosTitulos from './pages/QueryByGradosTitulos';
import QueryByProgramaEstudiosAcreditados from './pages/QueryByProgramaEstudiosAcreditados';
import QueryByAbogadosSancionados from './pages/QueryByAbogadosSancionados';
import QueryByAntecedentesPenales from './pages/QueryByAntecedentesPenales'; 
import QueryByConsultaPensionitas from './pages/QueryByConsultaPensionitas'; 
import QueryByComisarias from './pages/QueryByComisarias'; 


import Cookies from 'js-cookie';

function PrivateRoute({ children }) {
  const token = Cookies.get('authToken');
  return token ? children : <Navigate to="/login" />;
}

function App() {
  return ( 
    <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="*"
        element={
          <div className="app-container">
            <Sidebar />
            <div className="main-content">
              <Topbar />
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/query-dni" element={<PrivateRoute><QueryByDNI /></PrivateRoute>} />
                <Route path="/query-ruc" element={<PrivateRoute><QueryByRUC /></PrivateRoute>} />
                <Route path="/query-minedu" element={<PrivateRoute><QueryByMINEDU /></PrivateRoute>} />
                <Route path="/minedu-grados-titulos" element={<PrivateRoute><QueryByGradosTitulos/></PrivateRoute>} /> 
                <Route path="/minedu-programas-estudios-acreditados" element={<PrivateRoute><QueryByProgramaEstudiosAcreditados/></PrivateRoute>} />  
                <Route path="/minjus-abogados-sancionados" element={<PrivateRoute><QueryByAbogadosSancionados/></PrivateRoute>} />  
                <Route path="/query-minjus" element={<PrivateRoute><QueryByMINJUS /></PrivateRoute>} />
                <Route path="/query-mininter" element={<PrivateRoute><QueryByMININTER /></PrivateRoute>} />
                <Route path="/mininter-antecedentes-penales" element={<PrivateRoute><QueryByAntecedentesPenales /></PrivateRoute>} />
                <Route path="/mininter-pensionistas" element={<PrivateRoute><QueryByConsultaPensionitas /></PrivateRoute>} />
                <Route path="/mininter-comisarias" element={<PrivateRoute><QueryByComisarias /></PrivateRoute>} /> 
 
              </Routes>
            </div>
          </div>
        }
      />
    </Routes>
  </Router>
  );
}

export default App;
 