import React, { useState } from 'react';
import axios from 'axios';
import { FaSearch } from "react-icons/fa"; // Librería para iconos (react-icons)

function QueryByProgramaEstudiosAcreditados() {
  const [dni, setDni] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga

  const handleQuery = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_RENIEC;
      const response = await axios.get(`${apiUrl}/${dni}`);
      setResult(response.data);
    } catch (error) {
      alert('Error al consultar DNI');
    }
  };

  return (
    <div className="container mt-5">
    <div className="card shadow-lg"> 
      <div className="card-body">
      <h3>MINEDU</h3>
      <o><i>Consulta de programa de estudios licenciados</i></o>
      <hr className='hr_grados'></hr>
        <div className="mb-4">
          <label htmlFor="dniInput" className="form-label"> 
          <strong>Ingrese datos de la Institución</strong>
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="dniInput"
              value={dni}
              onChange={(e) => setDni(e.target.value)} 
              placeholder="Nombre de institución"
            />
            <button
              className="btn btn-primary"
              onClick={handleQuery}
              disabled={!dni || loading}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <FaSearch />
              )}
              <span className="ms-2">Consultar</span>
            </button>
          </div>
        </div>
        {result && (
          <div className="mt-4">
            <h5 className="mb-3 text-success">Resultado de la consulta:</h5>
            <table className="table table-striped table-bordered">
              <thead className="table-light">
                <tr>
                  <th scope="col">Título</th>
                  <th scope="col">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombres y Apellidos</td>
                  <td>{result.data.fullName}</td>
                </tr>
                <tr>
                  <td>DNI</td>
                  <td>{result.data.dni}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  </div>
  );
}

export default QueryByProgramaEstudiosAcreditados;
