import React, { useState } from 'react';
import axios from 'axios';
import { FaSearch } from "react-icons/fa"; // Librería para iconos (react-icons)

function QueryByAbogadosSancionados() {
  const [dni, setDni] = useState('');
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const [nombres, setNombres] = useState('');
  const [estadoSancion, setEstadoSancion] = useState('TODOS');
  const [colegioProfesional, setColegioProfesional] = useState('TODOS');
  const [institucionSancionadora, setInstitucionSancionadora] = useState('TODOS');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleQuery = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_RENIEC;
      const params = {
        dni,
        apellidoPaterno,
        apellidoMaterno,
        nombres,
        estadoSancion,
        colegioProfesional,
        institucionSancionadora,
      };
      const response = await axios.get(apiUrl, { params });
      setResult(response.data);
    } catch (error) {
      alert('Error al realizar la consulta');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h3>MINJUS</h3>
          <p><i>Consulta de Abogados Sancionados</i></p>
          <hr className="hr_grados"></hr>
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="dniInput" className="form-label">Documento de Identidad:</label>
                <input
                  type="text"
                  className="form-control"
                  id="dniInput"
                  value={dni}
                  onChange={(e) => setDni(e.target.value)}
                  placeholder="Ingrese el DNI"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="apellidoPaternoInput" className="form-label">Apellido Paterno:</label>
                <input
                  type="text"
                  className="form-control"
                  id="apellidoPaternoInput"
                  value={apellidoPaterno}
                  onChange={(e) => setApellidoPaterno(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="apellidoMaternoInput" className="form-label">Apellido Materno:</label>
                <input
                  type="text"
                  className="form-control"
                  id="apellidoMaternoInput"
                  value={apellidoMaterno}
                  onChange={(e) => setApellidoMaterno(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="nombresInput" className="form-label">Nombres:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nombresInput"
                  value={nombres}
                  onChange={(e) => setNombres(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="estadoSancionSelect" className="form-label">Estado de la Sanción:</label>
                <select
                  className="form-select"
                  id="estadoSancionSelect"
                  value={estadoSancion}
                  onChange={(e) => setEstadoSancion(e.target.value)}
                > 
                  <option>TODOS</option>
                  <option>Solo Vigentes</option>
                  <option>Solo Vencidos</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="colegioProfesionalSelect" className="form-label">Colegio Profesional:</label>
                <select
                  className="form-select"
                  id="colegioProfesionalSelect"
                  value={colegioProfesional}
                  onChange={(e) => setColegioProfesional(e.target.value)}
                >
                  <option>TODOS</option>
                  <option>Colegio de Abogados de Lima</option>
                  {/* Agrega más opciones según corresponda */}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="institucionSancionadoraSelect" className="form-label">Institución Sancionadora:</label>
                <select
                  className="form-select"
                  id="institucionSancionadoraSelect"
                  value={institucionSancionadora}
                  onChange={(e) => setInstitucionSancionadora(e.target.value)}
                >
                  <option>TODOS</option> 
                  {/* Agrega más opciones según corresponda */}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleQuery}
                disabled={loading}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  <FaSearch />
                )}
                <span className="ms-2">Consultar</span>
              </button>
            </div>
          </form>
          {result && (
            <div className="mt-4">
              <h5 className="mb-3 text-success">Resultado de la consulta:</h5>
              <table className="table table-striped table-bordered">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Campo</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nombres y Apellidos</td>
                    <td>{result.data.fullName}</td>
                  </tr>
                  <tr>
                    <td>DNI</td>
                    <td>{result.data.dni}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QueryByAbogadosSancionados;
