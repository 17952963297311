import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Para estilos adicionales

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GRML}/auth/login`, {
        email,
        password,
      });
      if (response.data.success) { 
        Cookies.set('authToken', response.data.data.token, { expires: 1 }); // Token válido por 1 día 
        Cookies.set('userName', response.data.data.user.name, { expires: 1 }); // Guardar el nombre del usuario
        navigate('/home');
      } else {
        setError('Autenticación fallida. Verifica tus credenciales.');
      }
    } catch (err) {
      setError('Error al conectar con el servidor. Intenta nuevamente.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card shadow p-4">
        {/* Agregar la imagen del logo */}
        <div className="text-center mb-4">
          <img src="/logo_principal.png" alt="Logo" className="image-principal" />
        </div>
        {/* Título del formulario */}
        <h2 className="text-center mb-2">SISTEMA DE INTEROPERABILIDAD</h2>
        <hr></hr>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Usuario</label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Ingrese su usuario"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Contraseña</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Ingrese su contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">Iniciar Sesión</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
