import React, { useState } from 'react';
import './Sidebar.css';
import { NavLink, useLocation } from 'react-router-dom';
import {
  FaHome,
  FaIdCard,
  FaBuilding,
  FaFolder,
  FaFileAlt,
  FaChevronDown,
  FaChevronRight,
  FaUniversity,
  FaUserShield,
  FaBalanceScale ,
} from 'react-icons/fa';



const menuItems = [
  {
    label: 'Inicio',
    icon: <FaHome className="nav-icon" />,
    path: '/home',
  },
  {
    label: 'Reniec',
    icon: <FaIdCard className="nav-icon" />,
    children: [
      {
        label: 'Consulta dni', 
        path: '/query-dni',
      }, 
    ], 
  },
  {
    label: 'Sunat',
    icon: <FaBuilding className="nav-icon" />,
    children: [
      {
        label: 'Consulta ruc', 
        path: '/query-ruc',
      }, 
    ],
  },
  {
    label: 'Minedu',
    icon: <FaUniversity  className="nav-icon" />,
    children: [
      {
        label: 'Grados y titulos', 
        path: '/minedu-grados-titulos',
      }, 
      {
        label: 'Consulta de programa de estudios licenciados', 
        path: '/minedu-programas-estudios-acreditados',
      } 
    ],
  },
  {
    label: 'Minjus',
    icon: <FaBalanceScale    className="nav-icon" />,
    children: [
      {
        label: 'Consulta de Abagodas Sancionados', 
        path: '/minjus-abogados-sancionados',
      } 
    ],
  },
  {
    label: 'Mininter',
    icon: <FaUserShield  className="nav-icon" />,
    children: [
      {
        label: 'Antecedentes Policiales', 
        path: '/mininter-antecedentes-penales',
      },
      {
        label: 'Pensionistas por dni', 
        path: '/mininter-pensionistas',
      } ,
      {
        label: 'Comisarias y Unidades Policiales', 
        path: '/mininter-comisarias',
      }
    ],
  }, 
];

function Sidebar() {
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();

  const toggleMenu = (label) => {
    setOpenMenu((prev) => (prev === label ? null : label));
  };


    const MenuItem = ({ item }) => {
      const hasChildren = item.children && item.children.length > 0;
      const isOpen = openMenu === item.label;
    
      return (
        <li className={`nav-item ${location.pathname.startsWith(item.path) ? 'active-menu' : ''}`}>
          {hasChildren ? (
            <>
              <div className="nav-link has-children" onClick={() => toggleMenu( item.label )}> 
                {  item.icon } 
                <span>{  item.label  }</span> 
                &nbsp;
                <span className="menu-toggle-icon">{isOpen ? <FaChevronDown /> : <FaChevronRight />}</span>
              </div>
              {isOpen && <ul className="submenu">{item.children.map((child) => <MenuItem key={child.path} item={child} />)}</ul>}
            </>
          ) : (
            <NavLink to={item.path} className="nav-link">
              { item.icon }
              <span>{ item.label }</span>
            </NavLink>
          )}
        </li>
      );
    };

  const renderMenu = (items) => items.map((item) => <MenuItem key={item.label} item={item} />);


  return (
    
    <div className="sidebar">
      <div className="logo">
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" className="logo-image" />
        </div>
      </div>
      <ul className="menu">{renderMenu(menuItems)}</ul>
    </div>
   
  );
}

export default Sidebar;